<template>
  <k-layout>
    <header class="tw-bg-white tw-px-4">
      <div class="tw-relative tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-lg tw-py-12 md:tw-py-20">
        <img
          src="./img/k.svg"
          class="tw-absolute tw-bottom-0 tw-right-0 tw-max-h-80 lg:tw-opacity-100 tw-pointer-events-none"
        />

        <h2 class="tw-mt-8 tw-heading-1">
          {{ $t('koderia_about.title') }}
        </h2>

        <p class="tw-max-w-xl tw-mt-8 tw-pr-2">
          {{ $t('koderia_about.descriptions.unique_project') }}
        </p>
      </div>
    </header>

    <main class="tw-mt-8 tw-px-4 tw-mb-24">
      <div class="tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-lg">
        <div class="tw-flex tw-flex-col tw-space-y-6">
          <section class="tw-relative tw-bg-white tw-rounded-sm tw-p-8 lg:tw-p-12">
            <img
              src="./img/home-image.svg"
              alt="Domov"
              class="tw-absolute tw-bottom-0 tw-right-0 tw-mr-8 tw-hidden lg:tw-block"
            />

            <h3 class="tw-heading-3">{{ $t('navigation.home') }}</h3>
            <div class="tw-mt-2">
              <router-link :to="{ name: 'Home', params: { locale } }" class="kod-link-primary">
                koderia.sk{{ routeSufix }}
              </router-link>
            </div>
            <p class="tw-max-w-2xl tw-mt-6">
              {{ $t('koderia_about.descriptions.homepage') }}
            </p>
          </section>

          <section class="tw-bg-black tw-text-white tw-rounded-sm tw-p-8 lg:tw-p-12">
            <h3 class="tw-heading-3">{{ $t('navigation.jobs') }}</h3>
            <div class="tw-mt-2">
              <router-link
                :to="{ name: 'project-index', params: { locale } }"
                class="tw-text-koderia hover:tw-text-white"
              >
                koderia.sk{{ routeSufix }}/praca
              </router-link>
            </div>
            <p class="tw-max-w-2xl tw-mt-6">
              {{ $t('koderia_about.descriptions.job_section') }}
            </p>
          </section>

          <div class="tw-grid tw-gap-6 tw-grid-cols-1 lg:tw-grid-cols-2">
            <section class="tw-bg-white tw-rounded-sm tw-p-8 lg:tw-p-12">
              <h3 class="tw-heading-3">{{ $t('navigation.koderia_referral') }}</h3>
              <div class="tw-mt-2">
                <router-link :to="{ name: 'referral', params: { locale } }" class="kod-link-primary">
                  koderia.sk{{ routeSufix }}/referral
                </router-link>
              </div>
              <p class="tw-max-w-2xl tw-mt-6">
                {{ $t('koderia_about.descriptions.looking_for_colleague') }}
              </p>
            </section>

            <section class="tw-bg-white tw-rounded-sm tw-p-8 lg:tw-p-12">
              <h3 class="tw-heading-3">{{ $t('navigation.koderia_extra') }}</h3>
              <div class="tw-mt-2">
                <router-link :to="{ name: 'extra', params: { locale } }" class="kod-link-primary">
                  koderia.sk{{ routeSufix }}/extra
                </router-link>
              </div>
              <p class="tw-max-w-2xl tw-mt-6">
                {{ $t('koderia_about.descriptions.interested_offer') }}
              </p>
            </section>
          </div>

          <section class="tw-relative tw-bg-white tw-rounded-sm tw-p-8 lg:tw-p-12">
            <img
              src="./img/cv-image.svg"
              :alt="$t('global.resume')"
              class="tw-absolute tw-bottom-0 tw-right-0 tw-mr-8 tw-hidden lg:tw-block"
            />
            <h3 class="tw-heading-3">{{ $t('global.resume') }}</h3>
            <div class="tw-mt-2">
              <router-link :to="{ name: 'cv-landing-page', params: { locale } }" class="kod-link-primary">
                koderia.sk{{ routeSufix }}/cv
              </router-link>
            </div>
            <p class="tw-max-w-2xl tw-mt-6">
              {{ $t('koderia_about.descriptions.modern_resume') }}
            </p>
          </section>

          <div class="tw-grid tw-gap-6 tw-grid-cols-1 lg:tw-grid-cols-2">
            <section class="tw-bg-white tw-rounded-sm tw-p-8 lg:tw-p-12">
              <h3 class="tw-heading-3">{{ $t('navigation.adequate_salary') }}</h3>
              <div class="tw-mt-2">
                <router-link :to="{ name: 'Questionaire', params: { locale } }" class="kod-link-primary">
                  koderia.sk{{ routeSufix }}/adekvatny-plat
                </router-link>
              </div>
              <p class="tw-max-w-2xl tw-mt-6">
                {{ $t('koderia_about.descriptions.salary_tool') }}
              </p>
            </section>

            <section class="tw-bg-white tw-rounded-sm tw-p-8 lg:tw-p-12">
              <h3 class="tw-heading-3">{{ $t('navigation.calculator') }}</h3>
              <div class="tw-mt-2">
                <router-link :to="{ name: 'Calculator', params: { locale } }" class="kod-link-primary">
                  koderia.sk{{ routeSufix }}/mzdova-kalkulacka
                </router-link>
              </div>
              <p class="tw-max-w-2xl tw-mt-6">
                {{ $t('koderia_about.descriptions.trade_to_sro') }}
              </p>
            </section>
          </div>

          <section class="tw-relative tw-bg-white tw-rounded-sm tw-p-8 lg:tw-p-12">
            <img
              src="./img/blog-image.svg"
              alt="Blog"
              class="tw-absolute tw-bottom-0 tw-right-0 tw-mr-8 tw-hidden lg:tw-block"
            />

            <h3 class="tw-heading-3">{{ $t('navigation.blog') }}</h3>
            <div class="tw-mt-2">
              <a
                :href="`https://koderia.sk/blog${routeSufix}`"
                target="_blank"
                rel="noopener noreferrer"
                class="kod-link-primary"
              >
                koderia.sk/blog{{ routeSufix }}
              </a>
            </div>
            <p class="tw-max-w-2xl tw-mt-6">
              {{ $t('koderia_about.descriptions.blog') }}
            </p>
          </section>
        </div>
      </div>
    </main>
  </k-layout>
</template>

<script>
import { getRouteLocale } from '@web/constants/language'

export default {
  computed: {
    locale() {
      return getRouteLocale()
    },
    routeSufix() {
      return this.locale ? `/${ this.locale }` : ''
    }
  }
}
</script>
